import React from 'react'

import { DefaultSVGProps } from '@/common/types/components'

export const Cards = ({ width, height, color, ...attrs }: DefaultSVGProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...attrs}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.0002 5H17.0001H6.00019H3.1709C3.58274 3.83481 4.69397 3 6.00019 3H20.0002C21.657 3 23.0002 4.34315 23.0002 6V14C23.0002 15.3062 22.1653 16.4175 21.0001 16.8293V14.0128L21.0002 14V6C21.0002 5.44771 20.5525 5 20.0002 5Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 8H4C3.44772 8 3 8.44772 3 9V17C3 17.5523 3.44772 18 4 18H17C17.5523 18 18 17.5523 18 17V9C18 8.44771 17.5523 8 17 8ZM4 6C2.34315 6 1 7.34315 1 9V17C1 18.6569 2.34315 20 4 20H17C18.6569 20 20 18.6569 20 17V9C20 7.34315 18.6569 6 17 6H4Z"
      fill={color}
    />
    <rect x="3" y="10" width="15" height="2" fill={color} />
  </svg>
)
