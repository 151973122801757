import React, { memo } from 'react'

import { DefaultHTMLAttrs } from '@/common/types/components'

import * as S from './styles'

export type SkeletonProps = DefaultHTMLAttrs<HTMLDivElement> & {
  width?: string
  height?: string
  animation?: S.AnimationTypes
  variant?: S.VariantTypes
}

const Component = ({
  width = '100%',
  height = '100%',
  animation = 'wave',
  variant = 'text',
  ...attrs
}: SkeletonProps) => (
  <S.Container
    width={width}
    height={height}
    animation={animation}
    variant={variant}
    {...attrs}
  />
)

export const Skeleton = memo(Component)
