import styled, { css } from 'styled-components'

import { removePx } from '@/utils/remove-px'

type ContainerProps = {
  itemPadding: string
}

export const Container = styled.div<ContainerProps>`
  ${({ itemPadding }) => css`
    width: 100%;

    .slick-slide {
      padding-left: var(--carrossel-items-padding);
    }

    .slick-track > div:first-child {
      margin-left: -${removePx(itemPadding) / 2}px;
    }
  `}
`
