import React, { memo } from 'react'

import {
  PropsWithRequiredChildren,
  DefaultHTMLAttrs,
} from '@/common/types/components'

import * as S from './styles'

export type FooterProps = PropsWithRequiredChildren<DefaultHTMLAttrs>

const Component = ({ children, ...attrs }: FooterProps) => (
  <S.Container {...attrs}>{children}</S.Container>
)

export const Footer = memo(Component)
