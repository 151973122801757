import React, {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  ElementType,
  memo,
  ReactNode,
} from 'react'

import { DEFAULT_CLICK_HANDLER } from '@/common/constants'
import { PropsWithRequiredChildren } from '@/common/types/components'

import * as S from './styles'

type ButtonTypes =
  | AnchorHTMLAttributes<HTMLAnchorElement>
  | ButtonHTMLAttributes<HTMLButtonElement>

type BaseButtonProps = Omit<S.StyledButtonProps, 'hasIcon'> &
  ButtonTypes & {
    icon?: ReactNode
    as: ElementType
    onClick: () => void
  }

export type ButtonProps = PropsWithRequiredChildren<BaseButtonProps>

const Component = (props: ButtonProps) => {
  const { onClick, isLoading, children, icon, ...attrs } = props
  const handleClick = isLoading ? DEFAULT_CLICK_HANDLER : onClick

  attrs.as === 'a' && delete attrs.type

  return (
    <S.Container
      onClick={handleClick}
      isLoading={isLoading}
      hasIcon={!!icon}
      {...attrs}
    >
      {icon ? (
        <>
          <span>{children}</span>
          {icon}
        </>
      ) : (
        children
      )}
    </S.Container>
  )
}

Component.defaultProps = {
  type: 'button',
  variant: 'primary',
  className: '',
  fixedWidth: false,
  isLoading: false,
} as ButtonProps

export const Button = memo(Component)
