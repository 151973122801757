import React from 'react'

import { DefaultSVGProps } from '@/common/types/components'

export const RightArrow = ({
  width,
  height,
  color,
  ...attrs
}: DefaultSVGProps) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={width}
    height={height}
    {...attrs}
  >
    <path
      d="M20 12H3M14 5l7 7-7 7"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
