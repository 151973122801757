import React from 'react'

import { DefaultSVGProps } from '@/common/types/components'

export const Close = ({ width, height, color, ...attrs }: DefaultSVGProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...attrs}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.05599 11.47L3.29299 5.70702C2.90247 5.3165 2.90247 4.68333 3.29299 4.29281C3.68351 3.90228 4.31668 3.90228 4.7072 4.29281L10.4702 10.0558C10.8607 10.4463 10.8607 11.0795 10.4702 11.47C10.0797 11.8605 9.44651 11.8605 9.05599 11.47ZM15.293 17.707L11.8202 14.2342C11.4297 13.8437 11.4297 13.2106 11.8202 12.82C12.2107 12.4295 12.8439 12.4295 13.2344 12.82L16.7072 16.2928C17.0977 16.6833 17.0977 17.3165 16.7072 17.707C16.3167 18.0975 15.6835 18.0975 15.293 17.707Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.77314 11.7529L3.01015 5.98986C2.46341 5.44313 2.46341 4.5567 3.01015 4.00996C3.55688 3.46323 4.44331 3.46323 4.99005 4.00996L10.753 9.77296C11.2998 10.3197 11.2998 11.2061 10.753 11.7529C10.2063 12.2996 9.31988 12.2996 8.77314 11.7529ZM15.0101 17.9899L11.5374 14.5171C10.9906 13.9704 10.9906 13.0839 11.5374 12.5372C12.0841 11.9904 12.9705 11.9904 13.5173 12.5372L16.99 16.01C17.5368 16.5567 17.5368 17.4431 16.99 17.9899C16.4433 18.5366 15.5569 18.5366 15.0101 17.9899ZM3.29299 5.70702L9.05599 11.47C9.44651 11.8605 10.0797 11.8605 10.4702 11.47C10.8607 11.0795 10.8607 10.4463 10.4702 10.0558L4.7072 4.29281C4.31668 3.90228 3.68351 3.90228 3.29299 4.29281C2.90247 4.68333 2.90247 5.3165 3.29299 5.70702ZM11.8202 14.2342L15.293 17.707C15.6835 18.0975 16.3167 18.0975 16.7072 17.707C17.0977 17.3165 17.0977 16.6833 16.7072 16.2928L13.2344 12.82C12.8439 12.4295 12.2107 12.4295 11.8202 12.82C11.4297 13.2106 11.4297 13.8437 11.8202 14.2342Z"
      fill={color}
    />
    <path
      d="M4.7072 17.707L16.7072 5.70702C17.0977 5.3165 17.0977 4.68333 16.7072 4.29281C16.3167 3.90228 15.6835 3.90228 15.293 4.29281L3.29299 16.2928C2.90247 16.6833 2.90247 17.3165 3.29299 17.707C3.68351 18.0975 4.31668 18.0975 4.7072 17.707Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.99 5.98986L4.99004 17.9899C4.44331 18.5366 3.55688 18.5366 3.01015 17.9899C2.46341 17.4431 2.46341 16.5567 3.01015 16.01L15.0101 4.00996C15.5569 3.46323 16.4433 3.46323 16.99 4.00996C17.5368 4.5567 17.5368 5.44313 16.99 5.98986ZM16.7072 5.70702L4.7072 17.707C4.31668 18.0975 3.68351 18.0975 3.29299 17.707C2.90247 17.3165 2.90247 16.6833 3.29299 16.2928L15.293 4.29281C15.6835 3.90228 16.3167 3.90228 16.7072 4.29281C17.0977 4.68333 17.0977 5.3165 16.7072 5.70702Z"
      fill={color}
    />
  </svg>
)

Close.defaultProps = {
  width: 20,
  height: 21,
  color: '#8C91A9',
}
