import React, { ReactNode, memo, PropsWithChildren, useCallback } from 'react'

import InputMask from 'react-input-mask'

import { DefaultHTMLAttrs } from '@/common/types/components'
import { useControlled } from '@/common/hooks'
import { IconList, Icons } from '@/assets'

import * as S from './styles'

type IconClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => void

export type InputComponentProps = {
  id: string
  disabled?: boolean
  error?: boolean
  helper?: ReactNode
  iconLeft?: Icons | ReactNode
  iconRight?: Icons | ReactNode
  infoText?: string
  label?: string
  name?: string
  readOnly?: boolean
  success?: boolean
  type?: 'text' | 'number' | 'email' | 'password' | 'tel' | 'search' | 'url'
  value?: string | number
  defaultValue?: string | number
  dataTestId?: string
  maxLength?: number
  onlyNumber?: boolean
  onClickIconLeft?: IconClick
  onClickIconRight?: IconClick
}

export type DefaultInputProps<T = HTMLInputElement> = Omit<
  DefaultHTMLAttrs<T>,
  'css' | 'value' | 'defaultValue'
> &
  InputComponentProps

export type InputProps = DefaultInputProps & {
  mask?: string
  maskPlaceholder?: string | null
}

type ChangeEvent = React.ChangeEvent<HTMLInputElement>
type EventHandler = (event: ChangeEvent) => void

const InputIcon = (
  icon: Icons | ReactNode,
  Wrapper: ({ children }: PropsWithChildren<DefaultHTMLAttrs>) => JSX.Element,
  onClick?: IconClick,
) => {
  let children = icon
  if (typeof icon === 'string' && Object.keys(IconList).includes(icon)) {
    children = (
      <S.Icon
        name={icon as Icons}
        size={24}
        $hasClickHandler={Boolean(onClick)}
      />
    )
  }
  return <Wrapper onClick={onClick}>{children}</Wrapper>
}

const DefaultInput = React.forwardRef(
  (
    {
      id,
      disabled = false,
      error = false,
      helper,
      iconLeft,
      iconRight,
      infoText,
      label,
      name,
      placeholder,
      readOnly = false,
      success = false,
      type = 'text',
      className,
      style,
      dataTestId = '',
      onClickIconLeft,
      onClickIconRight,
      ...attrs
    }: DefaultInputProps,
    ref?: React.Ref<HTMLInputElement>,
  ) => (
    <S.Container
      disabled={disabled}
      className={className}
      style={style}
      data-testid={dataTestId || id}
    >
      {(label || helper) && (
        <S.Title>
          <S.Label
            htmlFor={id}
            error={error}
            success={success}
            disabled={disabled}
            data-testid={`${dataTestId || id}_input-label`}
          >
            {label}
          </S.Label>
          {helper && (
            <span data-testid={`${dataTestId || id}_input-helper`}>
              {helper}
            </span>
          )}
        </S.Title>
      )}
      <S.Box error={error} success={success} disabled={disabled}>
        {iconLeft && InputIcon(iconLeft, S.IconLeft, onClickIconLeft)}
        <S.Input
          id={id}
          name={name}
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          hasIconRight={Boolean(iconRight)}
          hasIconLeft={Boolean(iconLeft)}
          readOnly={readOnly}
          ref={ref}
          {...attrs}
        />
        {iconRight && InputIcon(iconRight, S.IconRight, onClickIconRight)}
      </S.Box>
      {infoText && (
        <S.InfoText
          error={error}
          success={success}
          disabled={disabled}
          data-testid={`${dataTestId || id}_input-info`}
        >
          {infoText}
        </S.InfoText>
      )}
    </S.Container>
  ),
)

const InputWithMask = React.forwardRef(
  (
    {
      mask,
      maskPlaceholder = null,
      disabled,
      value: valueProp,
      defaultValue,
      onChange,
      onInput,
      onBlur,
      onlyNumber = false,
      ...attrs
    }: InputProps,
    ref?: React.Ref<HTMLInputElement>,
  ) => {
    const [value, setValue] = useControlled({
      controlled: valueProp,
      defaultState: defaultValue,
    })

    const handleInputChange = useCallback(
      (callback?: EventHandler) => (event: ChangeEvent) => {
        const { target } = event
        // stotybook has an addon that replaces the value of onlyNumber
        // so it is necessary to compare a boolean with true
        if (onlyNumber === true) {
          target.value = target.value.replace(/\D/g, '')
        }

        if (target.value !== value) {
          setValue(target.value)
          callback?.(event)
        }
      },
      [onlyNumber, setValue, value],
    )

    if (mask) {
      return (
        <InputMask
          mask={mask}
          maskPlaceholder={maskPlaceholder}
          defaultValue={value}
          disabled={disabled}
          onBlur={onBlur}
          onChange={handleInputChange(onChange)}
          onInput={handleInputChange(onInput)}
        >
          <DefaultInput disabled={disabled} ref={ref} {...attrs} />
        </InputMask>
      )
    }

    return (
      <DefaultInput
        defaultValue={value}
        disabled={disabled}
        onBlur={onBlur}
        onChange={handleInputChange(onChange)}
        onInput={handleInputChange(onInput)}
        ref={ref}
        {...attrs}
      />
    )
  },
)

export const Input = memo(InputWithMask)
