import React, { useState, memo, ChangeEvent } from 'react'

import {
  DefaultHTMLAttrs,
  PropsWithRequiredChildren,
} from '@/common/types/components'

import RadioGroupContext from './context'

type BaseRadioGroupProps = DefaultHTMLAttrs<HTMLDivElement> & {
  name: string
  value?: string
  disabled?: boolean
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
}

export type RadioGroupProps = PropsWithRequiredChildren<BaseRadioGroupProps>

export const Component = ({
  children,
  name,
  disabled,
  value: valueProp = '',
  onChange,
  ...attrs
}: RadioGroupProps) => {
  const [value, setValue] = useState(valueProp)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
    onChange && onChange(event)
  }

  return (
    <RadioGroupContext.Provider value={{ name, value, disabled }}>
      <div {...attrs} role="radiogroup" onChange={handleChange}>
        {children}
      </div>
    </RadioGroupContext.Provider>
  )
}

export const RadioGroup = memo(Component)
