import React, { useRef, useEffect, useCallback, memo } from 'react'

import {
  PropsWithRequiredChildren,
  DefaultHTMLAttrs,
} from '@/common/types/components'
import { useControlled } from '@/common/hooks'
import { DEFAULT_ACCORDION_TRANSITION_DELAY } from '@/common/constants'

import * as S from './styles'

export type AccordionProps = Omit<DefaultHTMLAttrs, 'onChange'> & {
  expanded?: boolean
  defaultExpanded?: boolean
  onChange?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    isExpanded: boolean,
  ) => void
}

export const Component = ({
  children: childrenProp,
  expanded,
  defaultExpanded,
  onChange,
  ...attrs
}: PropsWithRequiredChildren<AccordionProps>) => {
  const detailsRef = useRef<HTMLDivElement>(null)
  const timeout = useRef<number>(0)
  const [summary, ...children] = React.Children.toArray(childrenProp)
  const [isExpanded, setIsExpanded] = useControlled({
    controlled: expanded,
    defaultState: defaultExpanded,
  })

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      clearTimeout(timeout.current)
      setIsExpanded(!isExpanded)
      onChange?.(event, !isExpanded)
    },
    [isExpanded, setIsExpanded, onChange],
  )

  const changeDetailsMaxHeight = useCallback(
    (detailsElement: HTMLDivElement, maxHeight: string, delay?: number) => {
      const { style } = detailsElement
      style.maxHeight = `${detailsElement.scrollHeight}px`
      return setTimeout(() => {
        style.maxHeight = maxHeight
      }, delay)
    },
    [],
  )

  useEffect(() => {
    const detailsElement = detailsRef.current as HTMLDivElement

    if (isExpanded) {
      timeout.current = changeDetailsMaxHeight(
        detailsElement,
        'inherit',
        DEFAULT_ACCORDION_TRANSITION_DELAY,
      )
    } else if (detailsElement.style.maxHeight !== '') {
      timeout.current = changeDetailsMaxHeight(detailsElement, '0px', 50)
    }
  }, [changeDetailsMaxHeight, isExpanded])

  return (
    <S.Container {...attrs}>
      <S.Summary onClick={handleClick} role="button">
        {summary}
      </S.Summary>
      <S.Details ref={detailsRef} role="region">
        <S.DetailPanel>{children}</S.DetailPanel>
      </S.Details>
    </S.Container>
  )
}

export const Accordion = memo(Component)
