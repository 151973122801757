import React, { memo } from 'react'

import { Icons, IconList } from '@/assets/icons'
import { DEFAULT_ICON_SIZE } from '@/common/constants'
import { DefaultSVGProps } from '@/common/types/components'

export type IconProps = DefaultSVGProps & {
  name: Icons
  size?: number
  color?: string
}

export const Component = ({
  name,
  size,
  color = 'var(--gray400)',
  width: widthProp,
  height: heightProp,
  style = {},
  ...attrs
}: IconProps) => {
  const IconComponent = IconList[name]
  const height = size || heightProp || DEFAULT_ICON_SIZE
  const width = size || widthProp || DEFAULT_ICON_SIZE

  return (
    <IconComponent
      role="img"
      aria-label={`${name} icon`}
      width={width}
      height={height}
      color={color}
      style={{
        ...style,
        cursor: attrs.onClick ? 'pointer' : 'inherit',
      }}
      {...attrs}
    />
  )
}

export const Icon = memo(Component)
