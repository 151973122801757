import { createContext } from 'react'

import { ModalOpenRequest, ModalSettings } from './types'

type ModalContext = {
  closeModal: () => void
  openModal: (settings: ModalOpenRequest) => void
  modalSettings: ModalSettings
}

export const ModalContext = createContext<ModalContext>({} as ModalContext)
