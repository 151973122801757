import React from 'react'

import { DefaultSVGProps } from '@/common/types/components'

export const Team = ({ width, height, color, ...attrs }: DefaultSVGProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...attrs}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.02369 14.43C8.06003 13.8071 9.2588 13.7123 9.98568 13.7123H14.0147C14.7416 13.7123 15.9403 13.8071 16.9767 14.43C18.0732 15.0891 18.8495 16.2539 18.8495 18.1155C18.8495 18.5208 18.4887 18.8493 18.0437 18.8493C17.5986 18.8493 17.2379 18.5208 17.2379 18.1155C17.2379 16.6747 16.6711 16.0049 16.0889 15.6549C15.4465 15.2688 14.6308 15.18 14.0147 15.18H9.98568C9.36956 15.18 8.55383 15.2688 7.91142 15.6549C7.32924 16.0049 6.76248 16.6747 6.76248 18.1155C6.76248 18.5208 6.40171 18.8493 5.95668 18.8493C5.51165 18.8493 5.15088 18.5208 5.15088 18.1155C5.15088 16.2539 5.92712 15.0891 7.02369 14.43Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0001 10.2877C12.9458 10.2877 13.7124 9.52104 13.7124 8.57535C13.7124 7.62966 12.9458 6.86302 12.0001 6.86302C11.0544 6.86302 10.2878 7.62966 10.2878 8.57535C10.2878 9.52104 11.0544 10.2877 12.0001 10.2877ZM12.0001 12C13.8915 12 15.4247 10.4667 15.4247 8.57535C15.4247 6.68396 13.8915 5.1507 12.0001 5.1507C10.1087 5.1507 8.57544 6.68396 8.57544 8.57535C8.57544 10.4667 10.1087 12 12.0001 12Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.79468 10.0822C6.79468 10.6118 6.36536 11.0411 5.83577 11.0411C5.30619 11.0411 4.87687 10.6118 4.87687 10.0822C4.87687 9.55258 5.30619 9.12327 5.83577 9.12327C6.36536 9.12327 6.79468 9.55258 6.79468 10.0822ZM7.75358 10.0822C7.75358 11.1413 6.89495 12 5.83577 12C4.7766 12 3.91797 11.1413 3.91797 10.0822C3.91797 9.023 4.7766 8.16437 5.83577 8.16437C6.89495 8.16437 7.75358 9.023 7.75358 10.0822ZM4.70773 12.9589C4.30068 12.9589 3.62937 13.0119 3.04902 13.3608C2.43494 13.7299 2.00024 14.3821 2.00024 15.4246C2.00024 15.6516 2.20227 15.8356 2.45149 15.8356C2.70071 15.8356 2.90274 15.6516 2.90274 15.4246C2.90274 14.6178 3.22013 14.422 3.54615 14.226C3.9059 14.0098 4.3627 13.9601 4.70773 13.9601H6.31871C6.9477 13.5286 7.61017 13.2915 8.21345 13.1645C7.7484 12.9904 7.2777 12.9589 6.96397 12.9589H4.70773ZM17.6816 13.9601C17.0526 13.5286 16.3901 13.2915 15.7868 13.1646C16.2519 12.9904 16.7226 12.9589 17.0363 12.9589H19.2925C19.6996 12.9589 20.3709 13.0119 20.9513 13.3608C21.5653 13.7299 22 14.3821 22 15.4246C22 15.6516 21.798 15.8356 21.5488 15.8356C21.2996 15.8356 21.0975 15.6516 21.0975 15.4246C21.0975 14.6178 20.7802 14.422 20.4541 14.226C20.0944 14.0098 19.6376 13.9601 19.2925 13.9601H17.6816ZM19.1233 10.0822C19.1233 10.6118 18.6939 11.0411 18.1644 11.0411C17.6348 11.0411 17.2054 10.6118 17.2054 10.0822C17.2054 9.55259 17.6348 9.12328 18.1644 9.12328C18.6939 9.12328 19.1233 9.55259 19.1233 10.0822ZM20.0822 10.0822C20.0822 11.1414 19.2235 12 18.1644 12C17.1052 12 16.2465 11.1414 16.2465 10.0822C16.2465 9.02301 17.1052 8.16438 18.1644 8.16438C19.2235 8.16438 20.0822 9.02301 20.0822 10.0822Z"
      fill={color}
    />
  </svg>
)
