import styled, { css } from 'styled-components'

import { DEFAULT_TAB_HORIZONTAL_PADDING } from '@/common/constants'

type StyledTabLabelProps = {
  selected: boolean
}

type StyledTabBarProps = StyledTabLabelProps

type StyledTabContainerProps = {
  disabled: boolean
}

const labelModifiers = {
  selected: () => css`
    font-weight: 600;
    color: var(--tab-label-selected-text-color);
  `,
}

const barModifiers = {
  selected: 'opacity: 1;',
}

const containerModifiers = {
  disabled: `
    opacity: 0.5;
    pointer-events: none;
  `,
}

export const Label = styled.label<StyledTabLabelProps>`
  ${({ selected }) => css`
    font-family: Inter;
    font-size: 20px;
    line-height: 24px;
    pointer-events: none;
    color: var(--tab-label-text-color);
    transition: 100ms ease-in-out;
    ${selected && labelModifiers.selected()}
  `}
`

export const Bar = styled.div<StyledTabBarProps>`
  ${({ selected }) => css`
    width: ${`calc(100% - ${DEFAULT_TAB_HORIZONTAL_PADDING * 2}px)`};
    position: absolute;
    bottom: 0;
    opacity: 0;
    height: 4px;
    transition: 180ms ease-in-out;
    background-color: var(--tab-bar-background-color);
    border-radius: var(--tab-bar-border-radius) var(--tab-bar-border-radius) 0px
      0px;
    ${selected && barModifiers.selected};
  `}
`

export const Container = styled.div<StyledTabContainerProps>`
  ${({ disabled }) => css`
    display: inline-block;
    padding: 24px ${DEFAULT_TAB_HORIZONTAL_PADDING}px 20px;
    position: relative;
    cursor: pointer;
    ${disabled && containerModifiers.disabled}
  `}
`
