import React from 'react'

import { DefaultSVGProps } from '@/common/types/components'

export const EyeCross = ({
  width,
  height,
  color,
  ...attrs
}: DefaultSVGProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...attrs}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8232 5.26263C14.1457 4.14753 12.1469 3.5 10 3.5C5.45455 3.5 1.57273 6.40267 0 10.5C0.934646 12.935 2.6848 14.948 4.90511 16.1807L6.27681 14.809C4.43258 13.9452 2.90869 12.4435 1.98182 10.5C3.48182 7.35467 6.55455 5.36667 10 5.36667C11.6197 5.36667 13.1571 5.80601 14.4874 6.5984L15.8232 5.26263ZM13.1994 7.88638C12.449 6.92041 11.2933 6.3 10 6.3C7.74545 6.3 5.90909 8.18533 5.90909 10.5C5.90909 11.7882 6.47791 12.9435 7.37105 13.7147L8.68376 12.402C8.10498 11.9789 7.72727 11.2845 7.72727 10.5C7.72727 9.212 8.74545 8.16667 10 8.16667C10.7855 8.16667 11.4784 8.5765 11.8867 9.19913L13.1994 7.88638ZM9.27952 14.6347L14.0448 9.86939C14.0752 10.0751 14.0909 10.2857 14.0909 10.5C14.0909 12.8147 12.2545 14.7 10 14.7C9.75426 14.7 9.51349 14.6776 9.27952 14.6347ZM8.42185 15.4924C8.93552 15.5853 9.46304 15.6333 10 15.6333C13.4545 15.6333 16.5182 13.6453 18.0182 10.5C17.5293 9.4749 16.8734 8.57273 16.092 7.82225L17.3872 6.52706C18.5189 7.62983 19.4177 8.98304 20 10.5C18.4273 14.5973 14.5455 17.5 10 17.5C8.91677 17.5 7.87123 17.3352 6.88564 17.0286L8.42185 15.4924Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.7071 1.79289C19.0976 2.18342 19.0976 2.81658 18.7071 3.20711L2.70711 19.2071C2.31658 19.5976 1.68342 19.5976 1.29289 19.2071C0.902369 18.8166 0.902369 18.1834 1.29289 17.7929L17.2929 1.79289C17.6834 1.40237 18.3166 1.40237 18.7071 1.79289Z"
      fill={color}
    />
  </svg>
)
