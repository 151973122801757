import { useState, useCallback } from 'react'

export type UseControlledProps<T> = {
  controlled?: T
  defaultState?: T
}

type UseControlledReturn<T> = [T | undefined, (newState: T) => void]

export const useControlled = function useController<T = unknown>({
  controlled,
  defaultState,
}: UseControlledProps<T>): UseControlledReturn<T> {
  const [state, setState] = useState(defaultState)
  const isUncontrolled = controlled === undefined
  const value = isUncontrolled ? state : controlled

  const setStateIfUncontrolled = useCallback(
    (newState) => {
      isUncontrolled && setState(newState)
    },
    [isUncontrolled],
  )

  return [value, setStateIfUncontrolled]
}
