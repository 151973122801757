import React, { PropsWithChildren, memo } from 'react'

import { DefaultHTMLAttrs } from '@/common/types/components'

import * as S from './styles'

export type TabPanelProps = DefaultHTMLAttrs<HTMLDivElement> & {
  value: string | number
  index: string | number
}

const Component = ({
  value,
  index,
  children,
  ...attrs
}: PropsWithChildren<TabPanelProps>) => {
  const show = value === index

  return (
    <S.Container show={show} role="tabpanel" {...attrs}>
      {children}
    </S.Container>
  )
}

export const TabPanel = memo(Component)
