import React, { PropsWithChildren, memo } from 'react'

import { DefaultHTMLAttrs } from '@/common/types/components'

import * as S from './styles'

export type TabsProps = Omit<DefaultHTMLAttrs<HTMLDivElement>, 'onChange'> & {
  value: string | number
  onChange?: (value: string | number) => void
}

const Component = ({
  value,
  children: childrenProp,
  onChange,
  ...attrs
}: PropsWithChildren<TabsProps>) => {
  const children = React.Children.map(childrenProp, (child, index) => {
    if (React.isValidElement(child)) {
      const childValue = child.props.value ?? index
      const selected = childValue === value
      const childProps = { selected, value: childValue, onClick: onChange }

      return React.cloneElement(child, childProps)
    }
    return child
  })

  return (
    <S.Container role="tablist" {...attrs}>
      {children}
    </S.Container>
  )
}

export const Tabs = memo(Component)
