import React, {
  memo,
  useCallback,
  useMemo,
  useRef,
  useState,
  PropsWithChildren,
} from 'react'

import { DefaultHTMLAttrs } from '@/common/types/components'
import { DEFAULT_CHILD_RECTS } from '@/common/constants'

import { TooltipPortal } from './components/TooltipPortal'
import * as S from './styles'

type BaseTooltipProps = {
  action?: string
  description: string
  interactive?: boolean
  position?: S.TooltipPositions
  title?: string
} & DefaultHTMLAttrs

export type TooltipProps = PropsWithChildren<BaseTooltipProps>

const Component = (props: TooltipProps) => {
  const {
    children,
    description,
    title = '',
    action = '',
    position = 'top',
    interactive = false,
    ...attrs
  } = props
  const tooltipRef = useRef<HTMLDivElement>(null)
  const timeout = useRef<number>(0)
  const [enabled, setEnabled] = useState(false)
  const [childRects, setChildRects] = useState(DEFAULT_CHILD_RECTS)
  const isInteractive = Boolean(interactive || action)

  const hide = useCallback(() => {
    if (isInteractive) {
      timeout.current = setTimeout(() => {
        setEnabled(false)
      })
    } else {
      setEnabled(false)
    }
  }, [isInteractive])

  const show = useCallback(() => {
    clearTimeout(timeout.current)

    if (enabled) {
      return
    }

    const { top, left, width, height } =
      tooltipRef.current?.getClientRects()[0] || DEFAULT_CHILD_RECTS

    setChildRects({ top, left, width, height })
    setEnabled(true)
  }, [enabled])

  const mouseListeners = useMemo(
    () =>
      isInteractive
        ? {
            onMouseEnter: () => clearTimeout(timeout.current),
            onMouseLeave: hide,
          }
        : {},
    [isInteractive, hide],
  )

  return (
    <S.Container
      ref={tooltipRef}
      {...attrs}
      onMouseOver={show}
      onMouseLeave={hide}
    >
      {children}

      {enabled && (
        <TooltipPortal
          childRects={childRects}
          position={position}
          {...mouseListeners}
        >
          <S.TooltipContent position={position}>
            {title && <S.TooltipTitle>{title}</S.TooltipTitle>}

            <S.TooltipDescription hasAction={Boolean(action)}>
              {description}
            </S.TooltipDescription>

            {action && (
              <S.TooltipAction onClick={hide}>{action}</S.TooltipAction>
            )}

            <S.TooltipArrow position={position} isInteractive={isInteractive} />
          </S.TooltipContent>
        </TooltipPortal>
      )}
    </S.Container>
  )
}

export const Tooltip = memo(Component)
