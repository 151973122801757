import React, { memo } from 'react'

import { CheckboxProps } from '@/components'
import { useControlled } from '@/common/hooks'

import * as S from './styles'

export type SwitchProps = Omit<CheckboxProps, 'indeterminate'>

export const Component = ({
  id,
  label = '',
  checked: checkedProp,
  disabled = false,
  onChange,
  ...attrs
}: SwitchProps) => {
  const [checked, setChecked] = useControlled<boolean>({
    controlled: checkedProp,
    defaultState: !!checkedProp,
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event)
    }
    setChecked(event.target.checked)
  }

  return (
    <S.Container disabled={disabled}>
      <S.Input
        {...attrs}
        type="checkbox"
        id={id}
        checked={checked}
        disabled={disabled}
        onChange={handleChange}
      />
      <S.Body>
        <S.Bar checked={!!checked} />
        <S.Circle checked={!!checked} />
      </S.Body>
      {label && <S.Label htmlFor={id}>{label}</S.Label>}
    </S.Container>
  )
}

export const Switch = memo(Component)
