import styled, { css } from 'styled-components'

import { NotificationSettings } from './types'

type ContainerProps = {
  type: NotificationSettings['type']
  show: boolean
  hide: boolean
  marginBottom: number
}

const displayModifiers = {
  show: 'opacity: 1;',
  hide: (marginBottom: number) => css`
    transform: translateX(430px);
    margin-bottom: ${marginBottom}px;
  `,
}

const colorsModifiers = {
  NULL: () => css`
    ${() => css`
      background-color: var(--notification-null-background-color);
    `}
  `,
  SUCCESS: () => css`
    ${() => css`
      background-color: var(--notification-success-background-color);
    `}
  `,
  ERROR: () => css`
    ${() => css`
      background-color: var(--notification-error-background-color);
    `}
  `,
  ALERT: () => css`
    ${() => css`
      background-color: var(--notification-alert-background-color);
    `}
  `,
}

export const Notifications = styled.div`
  position: fixed;
  right: 10px;
  top: 10px;
`

export const Container = styled.div`
  ${({ type, show, hide, marginBottom }: ContainerProps) => css`
    position: relative;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;
    padding: 18px;
    background-color: var(--notification-container-background-color);
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.08);
    border-radius: var(--notification-container-border-radius);
    margin-bottom: 10px;
    width: 420px;
    min-height: 100px;
    transition: all ease-in-out 200ms;
    opacity: 0;

    ${show && displayModifiers.show}
    ${hide && displayModifiers.hide(marginBottom)}

    &::after {
      position: absolute;
      content: '';
      background-color: var(--notification-bar-color);
      ${colorsModifiers[type]()}
      width: 8px;
      height: 60px;
      left: 0;
      top: 20px;
      border-radius: 0px 8px 8px 0px;
    }
  `}
`

export const Icon = styled.div`
  background-color: var(--notification-icon-color);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Clear = styled.div`
  justify-self: flex-end;
  margin-left: auto;
`

export const Message = styled.div`
  display: flex;
  flex-flow: column wrap;
  margin-left: 8px;
  max-width: 80%;
`

export const Title = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: var(--notification-title-font-size);
  line-height: 20px;
  color: var(--notification-title-text-color);
  margin-bottom: 4px;
`

export const Content = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: var(--notification-content-font-size);
  line-height: 16px;
  color: var(--notification-content-text-color);
`
