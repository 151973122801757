import React from 'react'

import { DefaultSVGProps } from '@/common/types/components'

export const Polygon = ({
  width,
  height,
  color,
  ...attrs
}: DefaultSVGProps) => (
  <svg
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...attrs}
  >
    <path
      d="m6.80001,9.26666c-0.4,0.53334 -1.2,0.53334 -1.6,0l-4,-5.33333c-0.49443,-0.65924 -0.02404,-1.6 0.8,-1.6l8,0c0.824,0 1.2944,0.94077 0.8,1.6l-4,5.33333z"
      fill={color}
    />
  </svg>
)
