import React from 'react'

import { DefaultSVGProps } from '@/common/types/components'

export const Pencil = ({ width, height, color, ...attrs }: DefaultSVGProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...attrs}
  >
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 19.875V16.5C3 16.2016 3.11853 15.9155 3.3295 15.7045L15.7045 3.3295C16.1438 2.89017 16.8562 2.89017 17.2955 3.3295L20.6705 6.7045C21.1098 7.14384 21.1098 7.85615 20.6705 8.2955L8.2955 20.6705C8.08452 20.8815 7.79837 21 7.5 21H4.125C3.50368 21 3 20.4963 3 19.875ZM16.5001 9.2841L18.284 7.50018L16.5 5.71617L14.7161 7.50009L16.5001 9.2841ZM14.9091 10.8751L13.1251 9.09108L5.25 16.9662V18.7502H7.03401L14.9091 10.8751Z"
        fill={color}
      />
    </g>
  </svg>
)
