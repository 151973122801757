import styled, { css } from 'styled-components'
import ReactDatePicker from 'react-datepicker'

export const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  flex-flow: row nowrap;
  margin-top: 10px;
`

export const YearContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`

export const MonthAndYearContainer = styled(YearContainer)`
  & > span:first-child {
    margin-right: 5px;
  }
`

export const MonthAndYear = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: var(--datepicker-month-and-year-text-color);
`

export const YearSelectContainer = styled.div<{ optionsLength: number }>`
  ${({ optionsLength }) => css`
    position: absolute;
    top: 0;
    width: 100px;
    height: ${optionsLength > 11 ? '297px' : `${optionsLength * 27}px`};
    background: white;
    z-index: var(--zIndexMaximum);
  `}
`

export const YearSelect = styled.div`
  position: relative;
  display: flex-box;
  flex-flow: column wrap;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  background-color: var(--datepicker-year-select-background-color);
  border-radius: var(--datepicker-year-select-border-radius);
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.08);
`

export const YearOption = styled.span`
  width: 100%;
  font-size: 0.8rem;
  color: var(--datepicker-year-select-text-color);
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
`

type Container = {
  isRangeDate: boolean
  popUpOpened: boolean
}

export const Container = styled.div<Container>`
  ${({ isRangeDate, popUpOpened }) => css`
    display: flex;
    position: relative;

    ${isRangeDate && popUpOpened
      ? `
      background-color: var(--datepicker-background-color);
      border-radius: var(--datepicker-border-radius);
      box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.08);
    `
      : ''}

    .react-datepicker {
      ${!isRangeDate
        ? `
      background-color: var(--datepicker-background-color);
      border-radius: var(--datepicker-border-radius);

      box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.08);
      `
        : ''}

      border: none;
      display: flex;
      flex-flow: column wrap;

      &__header {
        background-color: transparent;
        border: none;
      }

      &__day-names {
        margin-top: 8px;

        .react-datepicker__day-name {
          text-transform: capitalize;
          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: var(--datepicker-day-names-font-size);
          line-height: 18px;
          color: var(--datepicker-day-names-text-color);
        }

        > div:first-child,
        > div:nth-child(7) {
          color: var(--datepicker-weekend-text-color);
        }
      }

      &__month {
        .react-datepicker__week {
          height: 40px;
          border-bottom: 0.5px solid var(--datepicker-week-border-color);
          display: flex;
          justify-content: center;
          align-items: center;

          .react-datepicker__day {
            z-index: 0;
            color: var(--datepicker-day-text-color);

            &:hover {
              background-color: transparent;
            }

            &--keyboard-selected {
              color: var(--datepicker-current-day-text-color);
              background-color: transparent;
            }

            &--selected {
              position: relative;
              background-color: transparent;
              border-radius: 0;
              color: var(--datepicker-selected-day-text-color) !important;

              &:before {
                content: ' ' !important;
              }

              &:after {
                position: absolute;
                content: ' ';
                height: 28px;
                width: 28px;
                border-radius: 50%;
                z-index: -1;
                left: 0;
                top: 0;
                background: var(--datepicker-selected-range-background-color);
              }
            }

            &--today {
              position: relative;
              color: var(--datepicker-current-day-text-color);
              font-weight: normal;

              &:before {
                content: 'HOJE';
                position: absolute;
                top: -4px;
                left: 4px;
                color: var(--datepicker-current-day-text-color);
                font-family: Inter;
                font-style: normal;
                font-weight: normal;
                font-size: 8px;
                line-height: 12px;
              }
            }

            &.react-datepicker__day--in-selecting-range {
              background-color: transparent !important;
              position: relative;

              &:before {
                position: absolute;
                content: ' ';
                height: 28px;
                width: 33px;
                z-index: -1;
                left: -3px;
                top: 0;
                background-color: var(
                  --datepicker-range-in-selection-background-color
                );
              }
            }

            &--in-range {
              position: relative;
              border-radius: 0;
              background-color: transparent;
              color: var(--datepicker-range-in-range-text-color);

              &:before {
                position: absolute;
                content: ' ';
                height: 28px;
                width: 33px;
                z-index: -1;
                left: -3px;
                top: 0;
                background-color: var(
                  --datepicker-range-in-selection-background-color
                );
              }

              &.react-datepicker__day--outside-month {
                &:before {
                  opacity: 0;
                }
              }
            }

            &--range-end {
              &:before {
                left: -6px;
                background-color: var(
                  --datepicker-range-in-selection-background-color
                );
                border-radius: 0 40% 40% 0;
              }
            }

            &--range-start {
              &:before {
                right: -6px;
                background-color: var(
                  --datepicker-range-in-selection-background-color
                );
                border-radius: 40% 0 0 40%;
                top: 0;
                left: 0;
              }
            }

            &--weekend,
            &--disabled {
              color: var(--datepicker-disabled-text-color);
            }

            &--outside-month {
              pointer-events: none;
              color: transparent !important;
              margin: 0;
              width: 33.5px;
              border-bottom: 15px var(--datepicker-background-color) solid;
              border-radius: 0;

              &:before,
              &:after {
                background-color: transparent !important;
                background: transparent !important;
              }
            }
          }
        }

        > div:last-child {
          border: none;
        }
      }
    }
  `}
`

export const DatePicker = styled(ReactDatePicker)`
  ${({ isRightSide }: { isRightSide?: boolean }) => css`
    margin-left: ${isRightSide ? '16px' : '0'};
  `}
`

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 8px 0;
  padding: 0 9px;

  button {
    font-weight: bold;
  }

  > button:first-child {
    color: var(--datepicker-clear-text-color);
  }
`
