import React from 'react'

import { DefaultSVGProps } from '@/common/types/components'

export const Rectangle = ({
  width,
  height,
  color,
  ...attrs
}: DefaultSVGProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...attrs}
  >
    <g>
      <rect x="3" y="3" width="18" height="18" stroke={color} strokeWidth="2" />
    </g>
  </svg>
)
