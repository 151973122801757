import styled, { css } from 'styled-components'

import { Icon } from '@/components/Icon'

type StyledPolygonProps = {
  direction: 'DECREASING' | 'GROWING'
}

type StyledContainerProps = {
  maxHeight: string
}

export const Container = styled.div<StyledContainerProps>`
  ${({ maxHeight }) => css`
    width: 100%;
    max-height: ${maxHeight};
    overflow: auto;
  `}
`

export const Table = styled.table`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
`

export const Head = styled.thead`
  margin: 0 0 18px;
  background-color: var(--table-head-background-color);
  border-radius: var(--table-head-border-radius);
`

export const HeadRow = styled.tr`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  padding: 0 24px;
`

export const HeadCell = styled.th`
  color: var(--table-head-text-color);
  font-family: Inter;
  font-size: var(--table-head-font-size);
  font-weight: 600;
  line-height: 18px;
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  flex-grow: 1;
  flex-basis: 0;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  user-select: none;
`

export const Arrow = styled(Icon)<StyledPolygonProps>`
  ${({ direction }) => css`
    transform: ${direction === 'DECREASING' ? 'rotate(-180deg);' : 'none;'};
    margin-left: 8px;
  `}
`

export const Body = styled.tbody``

export const BodyRow = styled.tr`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  background-color: var(--table-body-background-color);
  padding: 24px;
  margin-bottom: 8px;
  border-radius: var(--table-body-border-radius);

  &:nth-of-type(even) {
    background: rgba(245, 246, 250, 0.5);
  }
`

export const BodyCell = styled.td`
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  flex-grow: 1;
  flex-basis: 0;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: var(--table-body-cell-font-size);
  line-height: 18px;
  color: var(--table-body-cell-text-color);
`

export const UserFeedbackRow = styled(BodyRow)`
  ${() => css`
    background-color: var(--table-user-feedback-background-color);
  `}
`
export const UserFeedbackCell = styled(BodyCell)`
  justify-content: center;
`

export const ErrorMessage = styled.span`
  ${() => css`
    display: flex;
    justify-content: center;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: var(--table-error-font-size);
    line-height: 18px;
    color: var(--table-error-message-text-color);
  `}
`

export const CellText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
