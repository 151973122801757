export const DEFAULT_ACCORDION_TRANSITION_DELAY = 300

export const DEFAULT_CLICK_HANDLER = () => null

export const DEFAULT_CHILD_RECTS = { top: 0, left: 0, width: 0, height: 0 }

export const DEFAULT_ICON_SIZE = 24

export const DEFAULT_CONTAINER_PADDING = 20

export const DEFAULT_TAB_HORIZONTAL_PADDING = 24
