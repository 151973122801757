import React, { useState, memo } from 'react'

import * as InputStyles from '@/components/Input/styles'
import { Icon } from '@/components/Icon'
import { DefaultHTMLAttrs } from '@/common/types/components'

import { InputComponentProps } from '../Input'
import * as S from './styles'

export type SelectNativeProps = {
  emptyOptionText?: string
  hideEmptyOption?: boolean
  options: Array<{ value: string | number; text: string }>
} & Omit<InputComponentProps, 'type' | 'iconRight' | 'placeholder'> &
  Omit<DefaultHTMLAttrs<HTMLSelectElement>, 'css'>

export const Component = React.forwardRef(
  (
    {
      id,
      disabled = false,
      error = false,
      helper,
      iconLeft,
      infoText,
      label,
      name,
      success = false,
      value: valueProp = '',
      emptyOptionText = 'Selecione',
      hideEmptyOption = false,
      options,
      onChange,
      className,
      style,
      ...attrs
    }: SelectNativeProps,
    ref?: React.Ref<HTMLSelectElement>,
  ) => {
    const Arrow = <Icon name="arrow-down" />
    const [value, setValue] = useState(valueProp)
    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
      setValue(event.target.value)
      onChange && onChange(event)
    }

    return (
      <InputStyles.Container
        disabled={disabled}
        className={className}
        style={style}
      >
        <InputStyles.Title>
          <InputStyles.Label
            htmlFor={id}
            error={error}
            success={success}
            disabled={disabled}
          >
            {label}
          </InputStyles.Label>
          {helper && <span>{helper}</span>}
        </InputStyles.Title>
        <InputStyles.Box error={error} success={success} disabled={disabled}>
          {iconLeft && <InputStyles.IconLeft>{iconLeft}</InputStyles.IconLeft>}
          <S.Select
            id={id}
            name={name}
            disabled={disabled}
            hasIconRight
            hasIconLeft={Boolean(iconLeft)}
            value={value}
            onChange={handleChange}
            ref={ref}
            {...attrs}
          >
            {!hideEmptyOption && <option value="">{emptyOptionText}</option>}
            {options.map((option) => (
              <option value={option.value} key={option.value}>
                {option.text}
              </option>
            ))}
          </S.Select>
          <S.IconRight>{Arrow}</S.IconRight>
        </InputStyles.Box>
        {infoText && (
          <InputStyles.InfoText
            error={error}
            success={success}
            disabled={disabled}
          >
            {infoText}
          </InputStyles.InfoText>
        )}
      </InputStyles.Container>
    )
  },
)

export const SelectNative = memo(Component)
