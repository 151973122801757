import styled, { keyframes } from 'styled-components'

import { Icon } from '@/components/Icon'

type OverlayProps = {
  opened: boolean
}

const scaleUp = keyframes`
  0% {
    transform: scale(.8) translateY(1000px);
    opacity: 0;
  }

  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }
`

export const Overlay = styled.div<OverlayProps>`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: var(--modal-overlay-z-index);
  background-color: var(--modal-overlay-background-color);
  transition: all 0.5s ease 0s;
  justify-content: center;
  align-items: center;

  & {
    display: ${({ opened }) => (opened ? 'flex' : 'none')};
  }

  & > div {
    animation: ${scaleUp} 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
`

export const Modal = styled.div`
  background-color: var(--modal-background-color);
  border-radius: var(--modal-border-radius);
  padding: 0 16px;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.08);
  min-width: 400px;
  position: relative;
  transition: transform 0.5s;
`

export const Close = styled(Icon).attrs({
  name: 'close',
  height: 20,
  width: 20,
})`
  position: absolute;
  right: 10px;
  top: 10px;
`

export const Header = styled.div`
  border-bottom: 1px solid var(--modal-header-border-color);
  user-select: none;
`

export const HeaderTitle = styled.h1`
  color: var(--modal-header-title-text-color);
  margin-top: 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: var(--modal-header-title-font-size);
  line-height: 30px;
`

export const Content = styled.div`
  padding: 20px 0;
`

export const Footer = styled.footer`
  border-top: 1px solid var(--modal-footer-border-color);
  display: flex;
  justify-content: flex-end;
  padding: 16px 0;
`
