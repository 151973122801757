import styled, { css, keyframes } from 'styled-components'

export type AnimationTypes = 'wave' | 'pulse' | 'disabled'
export type VariantTypes = 'rect' | 'circle' | 'text'

type StyledSkeleton = {
  width: string
  height: string
  animation: AnimationTypes
  variant: VariantTypes
}

type ModifierProps = {
  animation?: AnimationTypes
}

const pulse = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
`

const wave = keyframes`
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
`

const animationModifiers = {
  wave: () => css`
    overflow: hidden;

    &::after {
      transform: translateX(-100%);
      animation: ${wave} 1.5s linear 0.5s infinite;
      background: linear-gradient(
        90deg,
        transparent,
        var(--skeleton-animation-highlight),
        transparent
      );
    }
  `,
  pulse: () => css`
    &::after {
      animation: ${pulse} 1.5s ease-in-out 0.5s infinite;
      background: var(--skeleton-animation-highlight);
    }
  `,
}

const variantModifiers = {
  circle: ({ animation }: ModifierProps) => css`
    border-radius: 50%;

    &::after {
      ${animation === 'pulse' && 'border-radius: 50%'};
    }
  `,
  rect: () => css`
    border-radius: 0;

    &::after {
      border-radius: 0;
    }
  `,
  text: () => css`
    border-radius: var(--skeleton-border-radius);

    &::after {
      border-radius: var(--skeleton-border-radius);
    }
  `,
}

export const Container = styled.div<StyledSkeleton>`
  ${({ width, height, animation, variant }) => css`
    position: relative;
    display: inline-block;
    width: ${width};
    height: ${height};
    background-color: var(--skeleton-background-color);
    border-radius: var(--skeleton-border-radius);
    ${animation !== 'disabled' && animationModifiers[animation]()};
    ${variantModifiers[variant]({ animation })};

    &::after {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: '';
      position: absolute;
    }
  `}
`
