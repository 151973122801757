import React from 'react'

import { DefaultSVGProps } from '@/common/types/components'

export const PAI = ({ width, height, color, ...attrs }: DefaultSVGProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...attrs}
  >
    <path
      d="M6.7403 6.00145L2.56657 6C2.49942 6 2.45913 6.07161 2.49582 6.12587L3.91824 8.23764C3.94726 8.28032 3.99643 8.30637 4.04919 8.30637H6.7403C8.42221 8.30637 8.9767 8.84094 8.9767 9.83655C8.9767 10.8322 8.42221 11.3486 6.7403 11.3486H6.18534C6.10859 11.3486 6.06207 11.4309 6.10404 11.4928L7.47609 13.5332C7.51159 13.5863 7.57466 13.6167 7.63965 13.6118C10.3375 13.4127 11.5378 12.0315 11.5378 9.83655C11.5378 7.55044 10.1809 6.00145 6.7403 6.00145Z"
      fill={color}
    />
    <path
      d="M15.1013 12.59H13.2671C13.1515 12.59 13.0716 12.474 13.1126 12.365L14.0268 9.93324C14.0849 9.77868 14.3026 9.77844 14.3609 9.93324L15.2684 12.3469C15.3126 12.4643 15.2262 12.59 15.1013 12.59ZM19.9321 17.7553L15.5562 6.11598C15.5301 6.04605 15.4634 6 15.3893 6H13.0176C12.9433 6 12.8769 6.04605 12.8505 6.11598L11.8458 8.77391C11.8228 8.83515 11.819 8.9017 11.8331 8.9656C12.1933 10.598 11.66 12.4163 10.3174 13.2058C10.2102 13.2687 10.1313 13.3712 10.0872 13.4882L8.47457 17.7553C8.43045 17.873 8.51678 17.9986 8.64173 17.9986H10.8784C10.9527 17.9986 11.0192 17.9523 11.0455 17.8824L12.124 15.0138C12.1504 14.9438 12.2169 14.8973 12.2912 14.8973H16.1155C16.1898 14.8973 16.2563 14.9438 16.2827 15.0138L17.3612 17.8824C17.3875 17.9523 17.4542 17.9986 17.5283 17.9986H19.765C19.8899 17.9986 19.9762 17.873 19.9321 17.7553Z"
      fill={color}
    />
    <path
      d="M21.8214 6H19.6173C19.5188 6 19.4387 6.08054 19.4387 6.17964L19.4355 15.2558C19.4353 15.3643 19.455 15.4719 19.4931 15.5736L20.2505 17.5902C20.3071 17.7259 20.2929 17.8713 20.2294 17.9574C20.2167 17.9743 20.2287 17.9979 20.2498 17.9979L21.8214 17.9986C21.9199 17.9986 22 17.9183 22 17.819V6.17964C22 6.08078 21.9197 6 21.8214 6Z"
      fill={color}
    />
    <path
      d="M2.1626 6.24174L7.06181 13.5384C7.09659 13.5905 7.05797 13.6592 6.99394 13.6592H4.99473C4.89401 13.6592 4.81246 13.7378 4.81246 13.8348V17.824C4.81246 17.9214 4.73092 18 4.63019 18H2.18227C2.08178 18 2 17.9214 2 17.824V6.28803C2 6.20316 2.11464 6.17036 2.1626 6.24174Z"
      fill={color}
    />
  </svg>
)
