import React from 'react'

import { DefaultSVGProps } from '@/common/types/components'

export const Eye = ({ width, height, color, ...attrs }: DefaultSVGProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...attrs}
  >
    <path
      d="M10 1.86667C13.4455 1.86667 16.5182 3.85467 18.0182 7C16.5182 10.1453 13.4545 12.1333 10 12.1333C6.54545 12.1333 3.48182 10.1453 1.98182 7C3.48182 3.85467 6.55455 1.86667 10 1.86667ZM10 0C5.45455 0 1.57273 2.90267 0 7C1.57273 11.0973 5.45455 14 10 14C14.5455 14 18.4273 11.0973 20 7C18.4273 2.90267 14.5455 0 10 0ZM10 4.66667C11.2545 4.66667 12.2727 5.712 12.2727 7C12.2727 8.288 11.2545 9.33333 10 9.33333C8.74545 9.33333 7.72727 8.288 7.72727 7C7.72727 5.712 8.74545 4.66667 10 4.66667ZM10 2.8C7.74545 2.8 5.90909 4.68533 5.90909 7C5.90909 9.31467 7.74545 11.2 10 11.2C12.2545 11.2 14.0909 9.31467 14.0909 7C14.0909 4.68533 12.2545 2.8 10 2.8Z"
      fill={color}
    />
  </svg>
)
