import React, {
  PropsWithChildren,
  memo,
  ReactNode,
  useMemo,
  useState,
} from 'react'

import { Button } from '@/components/Button'
import { DEFAULT_CLICK_HANDLER } from '@/common/constants'
import { ArrayTwoOrMore } from '@/common/types/components'

import * as S from './styles'

export type StepProps = {
  component: ReactNode
  key: number | string
  order?: number
}

export type WizardProps = {
  jumpHandler?(): void
  finishHandler?(): void
  onStepChange?(): void
  buttonAsLink?: boolean
  steps: ArrayTwoOrMore<StepProps>
}

const Component = (props: PropsWithChildren<WizardProps>) => {
  const {
    steps,
    jumpHandler,
    finishHandler,
    buttonAsLink,
    onStepChange,
  } = props
  const [currentStep, setCurrentStep] = useState(0)
  const Ellipses = useMemo(
    () =>
      steps.map((step, index) => (
        <S.Ellipse
          data-id={index}
          onClick={() => setCurrentStep(index)}
          active={steps[currentStep].key === step.key}
          key={step.key}
        />
      )),
    [steps, currentStep],
  )

  const handleStepChange = (cb: () => void) => {
    onStepChange && onStepChange()
    cb()
  }
  return (
    <S.Wizard>
      <S.WizardBody>{steps[currentStep].component}</S.WizardBody>
      <S.Footer>
        <S.EllipsisContainer>{Ellipses}</S.EllipsisContainer>
        {currentStep > 0 && (
          <Button
            fixedWidth
            onClick={() =>
              handleStepChange(() => setCurrentStep(currentStep - 1))
            }
            variant={buttonAsLink ? 'link' : 'secondary'}
          >
            Anterior
          </Button>
        )}
        {currentStep === 0 && (
          <Button
            fixedWidth
            onClick={jumpHandler}
            variant={buttonAsLink ? 'link' : 'secondary'}
          >
            Pular
          </Button>
        )}
        {currentStep < steps.length - 1 && (
          <Button
            fixedWidth
            onClick={() =>
              handleStepChange(() => setCurrentStep(currentStep + 1))
            }
            variant={buttonAsLink ? 'link' : 'primary'}
          >
            Próximo
          </Button>
        )}
        {currentStep === steps.length - 1 && (
          <Button
            fixedWidth
            onClick={finishHandler || DEFAULT_CLICK_HANDLER}
            variant={buttonAsLink ? 'link' : 'primary'}
          >
            Finalizar
          </Button>
        )}
      </S.Footer>
    </S.Wizard>
  )
}

export const Wizard = memo(Component)
