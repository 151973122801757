import React from 'react'

import * as S from './styles'
import { Button } from '../Button'

const Footer = ({
  onClearClick,
  onApplyClick,
  visible,
}: {
  onClearClick: () => void
  onApplyClick: () => void
  visible: boolean
}) =>
  visible ? (
    <S.Footer>
      <Button variant="link" onClick={onClearClick}>
        LIMPAR
      </Button>
      <Button variant="link" onClick={onApplyClick}>
        APLICAR
      </Button>
    </S.Footer>
  ) : null

export default Footer
