import styled, { css } from 'styled-components'

type StyledTabPanel = {
  show: boolean
}

const modifiers = {
  show: 'display: block',
}

export const Container = styled.div<StyledTabPanel>`
  ${({ show }) => css`
    display: none;
    border-top: 1px solid var(--tab-panel-border-color);
    ${show && modifiers.show}
  `}
`
