import React from 'react'

import { DefaultSVGProps } from '@/common/types/components'

export const CheckboxIndeterminate = ({
  width,
  height,
  color,
  ...attrs
}: DefaultSVGProps) => (
  <svg
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...attrs}
  >
    <path
      d="M6 11C6 8.23858 8.23858 6 11 6H21C23.7614 6 26 8.23858 26 11V21C26 23.7614 23.7614 26 21 26H11C8.23858 26 6 23.7614 6 21V11Z"
      fill={color}
    />
    <path
      d="M9 16C9 15.4477 9.44772 15 10 15H16H22C22.5523 15 23 15.4477 23 16V16C23 16.5523 22.5523 17 22 17H10C9.44772 17 9 16.5523 9 16V16Z"
      fill="var(--checkbox-indeterminate-check-color)"
    />
  </svg>
)
