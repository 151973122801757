import React, { useState, useMemo, useRef, useCallback } from 'react'

import { useOutsideClick } from '@/common/hooks'

import { Icon } from '../Icon'
import * as S from './styles'
import { DatePickerHeader } from './types'

const months = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
]

type YearProps = {
  month: number
  year: number
  changeYear: (year: number) => void
  updateDate: (date: Date | undefined) => void
  startYear?: number
}

const Year = ({
  month,
  year,
  startYear,
  changeYear,
  updateDate,
}: YearProps) => {
  const [visible, setVisible] = useState<boolean>(false)

  const selectContainer = useRef<HTMLDivElement>(null)
  useOutsideClick(selectContainer, () => visible && setVisible(false))

  const initialYear = useMemo(() => new Date().getFullYear(), [])

  const options = useMemo(() => {
    const years = Array.from(
      { length: startYear ? initialYear - startYear : 100 },
      (_, index) => initialYear - (index + 1),
    )

    years.splice(0, 0, initialYear)
    return years
  }, [initialYear, startYear])

  const openSelectBox = useCallback(() => {
    options.length && setVisible(true)
  }, [options])

  return (
    <S.YearContainer>
      {visible && (
        <S.YearSelectContainer
          optionsLength={options.length}
          ref={selectContainer}
        >
          <S.YearSelect>
            {options.map((currentYear) => (
              <S.YearOption
                key={currentYear}
                data-testid={`year-option-${currentYear}`}
                onClick={() => {
                  setVisible(false)
                  changeYear(currentYear)
                  updateDate(new Date(year, month, 1))
                }}
              >
                {currentYear}
              </S.YearOption>
            ))}
          </S.YearSelect>
        </S.YearSelectContainer>
      )}
      <S.MonthAndYear onClick={openSelectBox}>{year}</S.MonthAndYear>
      <Icon name="arrow-down" onClick={openSelectBox} />
    </S.YearContainer>
  )
}

Year.defaultProps = {
  startYear: undefined,
}

const Header = ({
  date,
  decreaseMonth,
  increaseMonth,
  changeYear,
  updateDate,
  startYear,
}: DatePickerHeader) => {
  const currentMonth = months[date.getMonth()]

  return (
    <S.Header data-testid="datepicker-header">
      <Icon name="arrow-left" onClick={decreaseMonth} />
      <S.MonthAndYearContainer>
        <S.MonthAndYear>{`${currentMonth} `}</S.MonthAndYear>
        <Year
          changeYear={changeYear}
          updateDate={updateDate}
          month={date.getMonth()}
          year={date.getFullYear()}
          startYear={startYear}
        />
      </S.MonthAndYearContainer>
      <Icon name="arrow-right" onClick={increaseMonth} />
    </S.Header>
  )
}

export default Header
