import React from 'react'

import { DefaultSVGProps } from '@/common/types/components'

export const RadioOff = ({
  width,
  height,
  color,
  ...attrs
}: DefaultSVGProps) => (
  <svg
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...attrs}
  >
    <circle cx="16" cy="16" r="9" stroke={color} strokeWidth="2" />
  </svg>
)
