import React from 'react'

import { DefaultSVGProps } from '@/common/types/components'

export const Clear = ({ width, height, color, ...attrs }: DefaultSVGProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...attrs}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5856 12L7.40366 8.81802L8.81787 7.40381L11.9999 10.5858L15.1818 7.40381L16.596 8.81802L13.4141 12L16.596 15.182L15.1818 16.5962L11.9999 13.4142L8.81787 16.5962L7.40366 15.182L10.5856 12Z"
      fill="#8C91A9"
    />
  </svg>
)
