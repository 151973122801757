import { useEffect, MutableRefObject } from 'react'

export const useOutsideClick = (
  reference: MutableRefObject<HTMLElement | undefined | null>,
  onOutsideClick: () => void,
) => {
  const handleClick = (event: MouseEvent) => {
    if (!reference?.current?.contains(event.target as HTMLElement)) {
      onOutsideClick()
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  })
}
