import styled, { css, keyframes } from 'styled-components'

export const ANIMATION_TIME = 1000

export const openSheetAnimation = keyframes`
  0% {
    background: transparent;
  }

  100% {
    background: var(--bottom-sheet-overlay-background-color);
  }
`

export const Overlay = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: var(--bottom-sheet-overlay-z-index);
  background: var(--bottom-sheet-overlay-background-color);
  transition: all 0.5s ease 0s;
  justify-content: center;
  align-items: center;
  animation: ${openSheetAnimation} ${ANIMATION_TIME}ms;
`

const swipeDown = keyframes`
  0% {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }

  100% {
    transform: scale(.8) translateY(1000px);
    opacity: 0;
  }
`

const swipeUp = keyframes`
  0% {
    transform: scale(.8) translateY(1000px);
    opacity: 0;
  }

  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }
`

export const Container = styled.div<{ down: number; closeAnimation: boolean }>`
  ${({ down, closeAnimation }) => css`
    display: flex;
    flex-flow: column wrap;
    background-color: var(--bottom-sheet-background-color);
    position: fixed;
    width: 100%;
    border-top-left-radius: var(--radiiLg);
    border-top-right-radius: var(--radiiLg);
    bottom: ${down > 0 ? `-${down}px` : '0'};
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.08);

    animation: ${closeAnimation ? swipeDown : swipeUp} ${ANIMATION_TIME}ms
      cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  `}
`

export const HandlerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 28px;
`

export const Handler = styled.div`
  background: var(--bottom-sheet-handler-background-color);
  border-radius: var(--radiiMd);
  width: 48px;
  height: 4px;
`

export const Content = styled.div`
  padding: 10px 24px;
`
