import styled from 'styled-components'

import { DEFAULT_CONTAINER_PADDING } from '@/common/constants'

export const Container = styled.footer`
  border-top: 0.5px solid var(--footer-border-color);
  width: calc(100% + ${DEFAULT_CONTAINER_PADDING}px);
  padding: 32px ${DEFAULT_CONTAINER_PADDING}px 8px;
  margin-top: ${DEFAULT_CONTAINER_PADDING * 2}px;
  display: flex;
  justify-content: flex-end;

  > button + button {
    margin-left: 16px;
  }
`
