import styled, { css } from 'styled-components'

import { Icon as UIIcon } from '@/components/Icon'

type StyledContainerProps = {
  disabled: boolean
}

type StyledTextProps = StyledContainerProps & {
  error: boolean
  success: boolean
}

type StyledBoxProps = StyledTextProps

export type StyledInputProps = {
  hasIconRight: boolean
  hasIconLeft: boolean
}

const textModifiers = {
  success: () => css`
    color: var(--input-success-label-text-color);
  `,
  error: () => css`
    color: var(--input-error-label-text-color);
  `,
  disabled: () => css`
    color: var(--input-disabled-label-text-color);
  `,
}

const boxModifiers = {
  success: () => css`
    background-color: var(--input-success-input-background-color);
  `,
  error: () => css`
    background-color: var(--input-error-input-background-color);
  `,
}

export const Title = styled.div`
  display: flex;
  margin-bottom: 4px;
`

export const Label = styled.label<StyledTextProps>`
  ${({ error, success, disabled }) => css`
    font-size: var(--input-label-font-size);
    line-height: 16px;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: 0;
    font-family: Inter;
    color: var(--input-label-text-color);
    ${error && textModifiers.error()}
    ${success && textModifiers.success()}
    ${disabled && textModifiers.disabled()}
  `}
`

export const Box = styled.div<StyledBoxProps>`
  ${({ error, success, disabled }) => css`
    height: 40px;
    background: var(--gray100);
    border-radius: var(--radiiMd);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    ${error && !disabled && boxModifiers.error()}
    ${success && !disabled && boxModifiers.success()}
  `}
`

const placeholder = () => css`
  font-family: Inter;
  font-weight: bold;
  font-size: var(--input-placeholder-font-size);
  color: var(--input-placeholder-text-color);
`

export const inputStyle = ({
  hasIconRight,
  hasIconLeft,
}: StyledInputProps) => css`
  background: transparent;
  flex: 1;
  height: 100%;
  padding: 8px 16px;
  font-size: var(--input-font-size);
  font-family: Inter;

  ${hasIconRight && 'padding-right: 8px;'}
  ${hasIconLeft && 'padding-left: 8px;'}
`

export const Input = styled.input<StyledInputProps>`
  ${({ hasIconRight, hasIconLeft }) => css`
    ${inputStyle({ hasIconLeft, hasIconRight })}

    ::-webkit-input-placeholder {
      ${placeholder()}
    }

    :-ms-input-placeholder {
      ${placeholder()}
    }

    ::placeholder {
      ${placeholder()}
    }

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    [type='number'] {
      -moz-appearance: textfield;
    }
  `}
`

type StyledIconProps = {
  $hasClickHandler: boolean
}

export const Icon = styled(UIIcon)<StyledIconProps>`
  ${({ $hasClickHandler }) => css`
    &&& {
      cursor: ${$hasClickHandler ? 'pointer' : 'inherit'};
    }
  `}
`

export const IconLeft = styled.div`
  padding: 8px 0 8px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const IconRight = styled.div`
  padding: 8px 8px 8px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const InfoText = styled.span<StyledTextProps>`
  ${({ error, success, disabled }) => css`
    font-family: Inter;
    font-size: var(--input-info-font-size);
    text-align: right;
    line-height: 16px;
    position: absolute;
    bottom: 0;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: var(--input-info-text-color);
    ${error && textModifiers.error()}
    ${success && textModifiers.success()}
    ${disabled && textModifiers.disabled()}
  `}
`

export const Container = styled.div<StyledContainerProps>`
  ${({ disabled }) => css`
    display: inline-flex;
    flex-direction: column;
    position: relative;
    padding-bottom: 20px;
    ${disabled && 'pointer-events: none;'}
  `}
`
