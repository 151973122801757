import React, { memo, useMemo, PropsWithChildren } from 'react'

import { Icon } from '@/components/Icon'
import { DefaultHTMLAttrs } from '@/common/types/components'

import * as S from './styles'

export type HeaderProps = {
  title?: string
  steps?: number
  currentStep?: number
} & DefaultHTMLAttrs

const Component = ({
  title,
  steps,
  children,
  currentStep = 0,
  ...props
}: PropsWithChildren<HeaderProps>) => {
  const stepsArray = useMemo(
    () => !!steps && Array.from({ length: steps }, (_, index) => index + 1),
    [steps],
  )

  return (
    <S.Container {...props}>
      {title && <h2>{title}</h2>}
      {children}

      {stepsArray && (
        <S.StepsContainer aria-label="progress" data-steps={steps}>
          {stepsArray.map((step) => {
            const isCurrentStep = currentStep === step

            return (
              <S.StepNumber
                key={step}
                isCurrentStep={isCurrentStep}
                aria-current={isCurrentStep ? 'step' : false}
              >
                {currentStep > step ? (
                  <Icon
                    name="success"
                    aria-hidden="true"
                    color="var(--success500)"
                  />
                ) : (
                  `${step}`
                )}
              </S.StepNumber>
            )
          })}
        </S.StepsContainer>
      )}
    </S.Container>
  )
}

export const Header = memo(Component)
