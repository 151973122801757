import styled, { css } from 'styled-components'

export type TooltipPositions =
  | 'top-start'
  | 'top'
  | 'top-end'
  | 'bottom-start'
  | 'bottom'
  | 'bottom-end'

export type StyledTooltipProps = {
  position: TooltipPositions
}

export type StyledTooltipDescriptionProps = {
  hasAction: boolean
}

export type StyledTooltipArrowProps = StyledTooltipProps & {
  isInteractive: boolean
}

const contentModifiers = {
  top: css`
    margin-bottom: 8px;
  `,
  bottom: css`
    margin-top: 8px;
  `,
}

const arrowModifiers = {
  'top-start': css`
    left: 20px;
    bottom: -4px;
  `,
  top: css`
    left: 50%;
    transform: translateX(-50%) rotate(-135deg);
    bottom: -4px;
  `,
  'top-end': css`
    right: 20px;
    bottom: -4px;
  `,
  'bottom-start': css`
    left: 20px;
    top: -4px;
  `,
  bottom: css`
    left: 50%;
    transform: translateX(-50%) rotate(-135deg);
    top: -4px;
  `,
  'bottom-end': css`
    right: 20px;
    top: -4px;
  `,
}

const getCleanPosition = ({ position }: StyledTooltipProps) =>
  position.startsWith('top') ? 'top' : 'bottom'

export const TooltipContent = styled.div<StyledTooltipProps>`
  ${({ position }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    padding: 16px;
    background-color: var(--tooltip-content-background-color);
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.22);
    border-radius: var(--tooltip-content-border-radius);
    max-width: 248px;
    ${contentModifiers[getCleanPosition({ position })]}
  `}
`

const TooltipRegularText = styled.span`
  font-family: Inter;
  font-size: var(--tooltip-regular-font-size);
  line-height: 15px;
`

const TooltipBoldText = styled(TooltipRegularText)`
  font-weight: bold;
`

export const TooltipTitle = styled(TooltipBoldText)`
  color: var(--tooltip-title-text-color);
  margin-bottom: 4px;
`

export const TooltipDescription = styled(
  TooltipRegularText,
)<StyledTooltipDescriptionProps>`
  ${({ hasAction }) => css`
    color: var(--tooltip-description-text-color);
    margin-bottom: ${hasAction ? '16px' : '0'};
  `}
`

export const TooltipAction = styled(TooltipBoldText)`
  color: var(--tooltip-action-text-color);
  margin-left: auto;
  cursor: pointer;
`

export const TooltipArrow = styled.div<StyledTooltipArrowProps>`
  ${({ position, isInteractive }) => css`
    position: absolute;
    width: 16px;
    height: 16px;
    background-color: var(--tooltip-arrow-background-color);
    border-radius: var(--tooltip-arrow-border-radius);
    transform: rotate(-135deg);
    pointer-events: ${isInteractive ? 'all' : 'none'};
    ${arrowModifiers[position]}
  `}
`

export const Container = styled.div`
  display: inline-block;
`
