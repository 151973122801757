import React from 'react'

import { DefaultSVGProps } from '@/common/types/components'

export const Warning = ({
  width,
  height,
  color,
  ...attrs
}: DefaultSVGProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...attrs}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.9614 5.75H11.0386L11.1489 14.7423H12.8511L12.9614 5.75ZM12 15.5346C11.276 15.5346 10.75 16.1558 10.75 16.8923C10.75 17.6288 11.276 18.25 12 18.25C12.724 18.25 13.25 17.6288 13.25 16.8923C13.25 16.1558 12.724 15.5346 12 15.5346Z"
      fill="white"
    />
  </svg>
)
