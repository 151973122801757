import React from 'react'

import { DefaultSVGProps } from '@/common/types/components'

export const Success = ({
  width,
  height,
  color,
  ...attrs
}: DefaultSVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    width={width}
    height={height}
    {...attrs}
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M18 9.433L9.502 17 6 13.882l1.453-1.434 2.049 1.825L16.547 8 18 9.433z"
      clipRule="evenodd"
    />
  </svg>
)
