import React from 'react'

import { DefaultSVGProps } from '@/common/types/components'

export const Search = ({ width, height, color, ...attrs }: DefaultSVGProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...attrs}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5 16.7222C13.9364 16.7222 16.7222 13.9364 16.7222 10.5C16.7222 7.06356 13.9364 4.27778 10.5 4.27778C7.06356 4.27778 4.27778 7.06356 4.27778 10.5C4.27778 13.9364 7.06356 16.7222 10.5 16.7222ZM10.5 18.5C6.08172 18.5 2.5 14.9183 2.5 10.5C2.5 6.08172 6.08172 2.5 10.5 2.5C14.9183 2.5 18.5 6.08172 18.5 10.5C18.5 14.9183 14.9183 18.5 10.5 18.5Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.0961 19.1462L18.7423 16.9039C18.2038 16.3654 17.4423 16.3654 16.9039 16.9039C16.3654 17.4423 16.3654 18.2038 16.9039 18.7423L19.1462 21.0961C19.6846 21.6346 20.5577 21.6346 21.0961 21.0961C21.6346 20.5577 21.6346 19.6846 21.0961 19.1462Z"
      fill={color}
    />
  </svg>
)
