import React, { memo } from 'react'

import RadioGroupContext from '@/components/RadioGroup/context'
import { Icon } from '@/components/Icon'
import { DefaultHTMLAttrs } from '@/common/types/components'

import * as S from './styles'

export type RadioProps = DefaultHTMLAttrs<HTMLInputElement> & {
  id: string
  value: string
  name?: string
  checked?: boolean
  label?: string
  disabled?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const Component = ({
  id,
  value,
  label = '',
  name: nameProp,
  disabled: disabledProp = false,
  checked: checkedProp = false,
  onChange,
  style,
  className,
  color,
  ...attrs
}: RadioProps) => {
  const radioGroup = React.useContext(RadioGroupContext)

  let name = nameProp
  let checked = checkedProp
  let disabled = disabledProp

  if (radioGroup) {
    name = radioGroup.name
    checked = radioGroup.value === value
    disabled = Boolean(radioGroup.disabled)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(event)
  }

  return (
    <S.Container disabled={disabled} style={style} className={className}>
      <S.Input
        {...attrs}
        type="radio"
        id={id}
        value={value}
        name={name}
        disabled={disabled}
        checked={checked}
        onChange={handleChange}
      />
      {checked ? (
        <Icon name="radio-on" size={32} color={color || 'var(--primary500)'} />
      ) : (
        <Icon name="radio-off" size={32} />
      )}
      {label && <S.Label htmlFor={id}>{label}</S.Label>}
    </S.Container>
  )
}

export const Radio = memo(Component)
