import React from 'react'

import { DefaultSVGProps } from '@/common/types/components'

export const CheckboxOn = ({
  width,
  height,
  color,
  ...attrs
}: DefaultSVGProps) => (
  <svg
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...attrs}
  >
    <path
      d="M6 11C6 8.23858 8.23858 6 11 6H21C23.7614 6 26 8.23858 26 11V21C26 23.7614 23.7614 26 21 26H11C8.23858 26 6 23.7614 6 21V11Z"
      fill={color}
    />
    <path
      d="M11 17L14 20L21 13"
      stroke="var(--checkbox-check-color)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
