import React from 'react'

import { DefaultSVGProps } from '@/common/types/components'

export const ArrowRight = ({
  width,
  height,
  color,
  ...attrs
}: DefaultSVGProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...attrs}
  >
    <path
      d="M6.35535 2.41879C6.82916 1.8604 7.59453 1.8604 8.06834 2.41879L13.6446 8.9906C14.1185 9.54899 14.1185 10.451 13.6446 11.0094L8.06834 17.5812C7.59453 18.1396 6.82916 18.1396 6.35535 17.5812C5.88155 17.0228 5.88155 16.1208 6.35535 15.5624L11.0691 9.99284L6.35535 4.43758C5.88155 3.87919 5.8937 2.96286 6.35535 2.41879Z"
      fill={color}
    />
  </svg>
)
