import { ArrowDown } from './ArrowDown'
import { ArrowLeft } from './ArrowLeft'
import { ArrowRight } from './ArrowRight'
import { CardMoney } from './CardMoney'
import { Cards } from './Cards'
import { CheckboxIndeterminate } from './CheckboxIndeterminate'
import { CheckboxOff } from './CheckboxOff'
import { CheckboxOn } from './CheckboxOn'
import { Clear } from './Clear'
import { Close } from './Close'
import { Eye } from './Eye'
import { EyeCross } from './EyeCross'
import { House } from './House'
import { LeftArrow } from './LeftArrow'
import { Loading } from './Loading'
import { PAI } from './PAI'
import { Paper } from './Paper'
import { Pencil } from './Pencil'
import { Polygon } from './Polygon'
import { RadioOn } from './RadioOn'
import { RadioOff } from './RadioOff'
import { Rectangle } from './Rectangle'
import { RightArrow } from './RightArrow'
import { Search } from './Search'
import { Settings } from './Settings'
import { Success } from './Success'
import { Team } from './Team'
import { Warning } from './Warning'

export const IconList = {
  'arrow-down': ArrowDown,
  'arrow-left': ArrowLeft,
  'arrow-right': ArrowRight,
  'card-money': CardMoney,
  cards: Cards,
  'checkbox-indeterminate': CheckboxIndeterminate,
  'checkbox-off': CheckboxOff,
  'checkbox-on': CheckboxOn,
  clear: Clear,
  close: Close,
  eye: Eye,
  'eye-cross': EyeCross,
  house: House,
  loading: Loading,
  pai: PAI,
  paper: Paper,
  pencil: Pencil,
  polygon: Polygon,
  'radio-on': RadioOn,
  'radio-off': RadioOff,
  'right-arrow': RightArrow,
  'left-arrow': LeftArrow,
  search: Search,
  settings: Settings,
  success: Success,
  team: Team,
  rectangle: Rectangle,
  warning: Warning,
}

export type Icons = keyof typeof IconList
