import React from 'react'

import { DefaultSVGProps } from '@/common/types/components'

import * as S from './styles'

export const Loading = ({
  width,
  height,
  color,
  ...attrs
}: DefaultSVGProps) => (
  <S.Svg
    viewBox="0 0 24 24"
    fill="none"
    width={width}
    height={height}
    {...attrs}
  >
    <S.Circle cx="12" cy="12" r="8" stroke={color} strokeWidth="2" />
    <path
      d="M12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C10.5429 20 9.17669 19.6104 8 18.9297"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </S.Svg>
)
