import styled from 'styled-components'

import { DEFAULT_CONTAINER_PADDING } from '@/common/constants'

export const Wizard = styled.section`
  display: flex;
  flex-direction: column;
  min-width: 500px;
  padding: ${DEFAULT_CONTAINER_PADDING}px;
`

export const WizardBody = styled.div`
  display: flex;
  flex: 1 0 100%;
  align-items: center;
  justify-content: center;
`

type StepProps = {
  order?: number
}
export const Step = styled.div<StepProps>`
  display: flex;
  order: ${(props: StepProps) => props.order || 0};
`

type EllipseProps = {
  active?: boolean
}

export const Footer = styled.footer`
  display: flex;
  width: 100%;
  justify-content: center;
  height: 100px;
  position: relative;
  flex-wrap: wrap;
  background-color: var(--white100);
`

export const EllipsisContainer = styled.div`
  flex: 1 1 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  + button {
    margin-right: 8px;
  }
`

export const Ellipse = styled.div<EllipseProps>`
  height: 8px;
  width: 8px;
  border-radius: 100px;
  margin-right: 8px;
  background-color: ${(props: EllipseProps) =>
    props.active
      ? 'var(--wizard-active-background-color)'
      : 'var(--wizard-background-color)'};
`
