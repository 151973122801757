import React from 'react'

export type RadioGroupContextProps = {
  name: string
  value?: string
  disabled?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const RadioGroupContext = React.createContext<
  RadioGroupContextProps | undefined
>(undefined)

export default RadioGroupContext
