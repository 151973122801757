import React, { InputHTMLAttributes, memo } from 'react'

import { Icon } from '@/components/Icon'
import { useControlled } from '@/common/hooks'
import { Icons } from '@/assets'

import * as S from './styles'

export type CheckboxProps = InputHTMLAttributes<HTMLInputElement> & {
  id: string
  checked?: boolean
  disabled?: boolean
  indeterminate?: boolean
  label?: string
  color?: string
  size?: number
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const Component = React.forwardRef(
  (
    {
      id,
      label = '',
      name,
      checked: checkedProp,
      defaultChecked,
      disabled = false,
      indeterminate = false,
      color,
      size = 32,
      onChange,
      ...attrs
    }: CheckboxProps,
    ref?: React.Ref<HTMLInputElement>,
  ) => {
    const [checked, setChecked] = useControlled({
      controlled: checkedProp,
      defaultState: defaultChecked,
    })

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(event)
      }
      setChecked(event.target.checked)
    }

    return (
      <S.Container disabled={disabled}>
        <S.Input
          {...attrs}
          type="checkbox"
          ref={ref}
          name={name}
          id={id}
          defaultChecked={defaultChecked}
          disabled={disabled}
          onChange={handleChange}
        />
        {indeterminate ? (
          <Icon
            name="checkbox-indeterminate"
            size={size}
            color={color || 'var(--checkbox-indeterminate-color)'}
          />
        ) : (
          <Icon
            name={`checkbox-${checked ? 'on' : 'off'}` as Icons}
            size={size}
            color={color || 'var(--checkbox-color)'}
          />
        )}
        {label && <S.Label htmlFor={id}>{label}</S.Label>}
      </S.Container>
    )
  },
)

export const Checkbox = memo(Component)
