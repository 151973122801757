import React from 'react'

import { DefaultSVGProps } from '@/common/types/components'

export const ArrowLeft = ({
  width,
  height,
  color,
  ...attrs
}: DefaultSVGProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...attrs}
  >
    <path
      d="M13.6446 2.41879C13.1708 1.8604 12.4055 1.8604 11.9317 2.41879L6.35535 8.9906C5.88155 9.54899 5.88155 10.451 6.35535 11.0094L11.9317 17.5812C12.4055 18.1396 13.1708 18.1396 13.6446 17.5812C14.1185 17.0228 14.1185 16.1208 13.6446 15.5624L8.9309 9.99284L13.6446 4.43758C14.1185 3.87919 14.1063 2.96286 13.6446 2.41879Z"
      fill={color}
    />
  </svg>
)
