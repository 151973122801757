import React, { memo, useMemo, useState, useCallback, useRef } from 'react'

import ReactDatePicker, { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'

import 'react-datepicker/dist/react-datepicker.css'

import { useOutsideClick } from '@/common/hooks'
import { dateToObject } from '@/utils/date'

import Footer from './footer'
import Header from './header'
import { Input } from '../Input'
import * as S from './styles'
import { DatePickerProps, DatePickerCustomFormProps } from './types'

registerLocale('pt-BR', ptBR)

const Component = React.forwardRef(
  (
    {
      name,
      value,
      startDate: start,
      endDate: end,
      disabled = false,
      onChange,
      onStartDateChange,
      onEndDateChange,
      className,
      isRangeDate: rangeEnabled,
      footer = true,
      minDate,
      maxDate,
      ...attrs
    }: DatePickerProps,
    ref?: React.Ref<HTMLInputElement>,
  ) => {
    const valueReference = useRef<Date | undefined>()
    const [opened, setOpened] = useState<boolean>(false)

    const containerReference = useRef<HTMLDivElement>()
    const startDateReference = useRef<ReactDatePicker>()
    const endDateReference = useRef<ReactDatePicker>()

    const [startDate, setStartDate] = useState<Date | undefined>(
      rangeEnabled ? undefined : start || value,
    )
    const [endDate, setEndDate] = useState<Date | undefined>(end)

    React.useEffect(() => {
      if (!value || value === valueReference.current) return

      valueReference.current = value
      setStartDate(value)
    }, [value, startDate])

    const isRangeDate = useMemo(() => Boolean(end || rangeEnabled), [
      end,
      rangeEnabled,
    ])

    const CustomInput = React.forwardRef(
      (
        { value: inputValue, ...inputAttrs }: DatePickerCustomFormProps,
        inputRef?:
          | ((instance: HTMLInputElement | null) => void)
          | React.RefObject<HTMLInputElement>
          | null,
      ) => (
        <Input
          defaultValue={inputValue}
          ref={inputRef}
          {...inputAttrs}
          readOnly
        />
      ),
    )

    const handleDateToString = useCallback((date?: Date | null): string => {
      if (!date) return ''
      return dateToObject(date).formatted
    }, [])

    const closePopUp = useCallback(() => {
      startDateReference?.current?.setOpen?.(false)
      endDateReference?.current?.setOpen?.(false)
      setOpened(false)
    }, [])

    useOutsideClick(containerReference, closePopUp)

    const onCalendarOpen = useCallback(() => {
      if (!isRangeDate) return

      setOpened(true)
      startDateReference?.current?.setOpen?.(true)
      endDateReference?.current?.setOpen?.(true)
    }, [isRangeDate])

    const handleApplyClick = useCallback(() => {
      if (isRangeDate) {
        onStartDateChange?.(startDate as Date)
        onEndDateChange?.(endDate as Date)
      }

      onChange?.(startDate as Date)
      closePopUp()
    }, [
      onChange,
      onStartDateChange,
      onEndDateChange,
      startDate,
      endDate,
      isRangeDate,
      closePopUp,
    ])

    return (
      <S.Container
        isRangeDate={isRangeDate}
        popUpOpened={opened}
        ref={(reference) => {
          if (!reference) return
          containerReference.current = reference
        }}
      >
        <input
          ref={ref}
          style={{ display: 'none' }}
          value={
            isRangeDate
              ? JSON.stringify({
                  startDate: handleDateToString(startDate),
                  endDate: handleDateToString(endDate),
                })
              : handleDateToString(startDate)
          }
          onChange={() => {
            // Added to remove warning
          }}
        />

        <S.DatePicker
          showPopperArrow={false}
          selectsStart={isRangeDate}
          name={endDate ? `${name}-start` : name}
          selected={startDate}
          disabled={disabled}
          onChange={(date: Date) => {
            setStartDate(date)
            !footer && onChange?.(date)

            !isRangeDate &&
              !footer &&
              startDateReference.current?.setOpen(false)
          }}
          locale="pt-BR"
          dateFormat="P"
          ref={(reference: ReactDatePicker) => {
            if (!reference) return
            startDateReference.current = reference
          }}
          wrapperClassName={className}
          customInput={<CustomInput {...attrs} />}
          shouldCloseOnSelect={isRangeDate}
          renderCustomHeader={(props) => (
            <Header {...props} updateDate={setStartDate} />
          )}
          formatWeekDay={(dayOfWeekLabel) => dayOfWeekLabel.substring(0, 1)}
          onCalendarOpen={onCalendarOpen}
          startDate={startDate}
          endDate={endDate}
          inline={opened}
          minDate={minDate}
          maxDate={isRangeDate ? undefined : maxDate}
        >
          <Footer
            onClearClick={() => {
              setStartDate(undefined)
              onChange?.(undefined)
            }}
            onApplyClick={handleApplyClick}
            visible={!isRangeDate && footer}
          />
        </S.DatePicker>

        {isRangeDate && (
          <S.DatePicker
            isRightSide
            showPopperArrow={false}
            name={`${name}-end`}
            selectsEnd
            selected={endDate}
            disabled={disabled}
            onChange={(date: Date) => setEndDate(date)}
            ref={(reference: ReactDatePicker) => {
              if (!reference) return
              endDateReference.current = reference
            }}
            locale="pt-BR"
            dateFormat="P"
            wrapperClassName={className}
            customInput={<CustomInput {...attrs} />}
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            onCalendarOpen={onCalendarOpen}
            formatWeekDay={(dayOfWeekLabel) => dayOfWeekLabel.substring(0, 1)}
            renderCustomHeader={(props) => (
              <Header
                {...props}
                updateDate={setEndDate}
                startYear={startDate?.getFullYear()}
              />
            )}
            inline={opened}
            maxDate={maxDate}
          >
            <Footer
              onClearClick={() => {
                setStartDate(undefined)
                setEndDate(undefined)
                onChange?.()
                onStartDateChange?.()
                onEndDateChange?.()
              }}
              onApplyClick={handleApplyClick}
              visible={footer}
            />
          </S.DatePicker>
        )}
      </S.Container>
    )
  },
)

export const DatePicker = memo(Component)
