import { useEffect, PropsWithChildren, useCallback } from 'react'

import ReactDOM from 'react-dom'

import { TooltipPositions } from '@/components/Tooltip/styles'

export type TooltipPortalProps = {
  position: TooltipPositions
  childRects: {
    top: number
    left: number
    width: number
    height: number
  }
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}

export const TooltipPortal = (props: PropsWithChildren<TooltipPortalProps>) => {
  const { children, childRects, position, onMouseEnter, onMouseLeave } = props
  const el = document.createElement('div')

  const getScreenPosition = useCallback(
    (
      tooltipWidth: number,
      tooltipHeight: number,
    ): { xAxis: number; yAxis: number } => {
      const positions = {
        'top-start': {
          xAxis: childRects.left,
          yAxis: childRects.top - tooltipHeight,
        },
        top: {
          xAxis: childRects.left + childRects.width / 2 - tooltipWidth / 2,
          yAxis: childRects.top - tooltipHeight,
        },
        'top-end': {
          xAxis: childRects.left + childRects.width - tooltipWidth,
          yAxis: childRects.top - tooltipHeight,
        },
        'bottom-start': {
          xAxis: childRects.left,
          yAxis: childRects.top + childRects.height,
        },
        bottom: {
          xAxis: childRects.left + childRects.width / 2 - tooltipWidth / 2,
          yAxis: childRects.top + childRects.height,
        },
        'bottom-end': {
          xAxis: childRects.left + childRects.width - tooltipWidth,
          yAxis: childRects.top + childRects.height,
        },
      }
      return positions[position]
    },
    [childRects, position],
  )

  useEffect(() => {
    document.body.appendChild(el)

    const { xAxis, yAxis } = getScreenPosition(el.offsetWidth, el.offsetHeight)
    const transform = `translate3d(${xAxis}px, ${yAxis}px, 0px)`

    el.onmouseleave = onMouseLeave || null
    el.onmouseover = onMouseEnter || null
    el.style.cssText = `position: absolute; top: 0px; left: 0px; transform: ${transform};`

    return () => {
      document.body.removeChild(el)
    }
  }, [getScreenPosition, el, onMouseEnter, onMouseLeave])

  return ReactDOM.createPortal(children, el)
}
