import React, { memo } from 'react'

import { DefaultHTMLAttrs } from '@/common/types/components'

import * as S from './styles'

export type TabProps = Omit<DefaultHTMLAttrs<HTMLDivElement>, 'onClick'> & {
  label: string
  value?: string | number
  selected?: boolean
  disabled?: boolean
  onClick?: (value?: string | number) => void
}

const Component = ({
  label,
  value,
  selected = false,
  disabled = false,
  onClick,
  ...attrs
}: TabProps) => {
  const handleClick = () => {
    if (!disabled && !selected && onClick) {
      onClick(value)
    }
  }

  return (
    <S.Container
      onClick={handleClick}
      disabled={disabled}
      role="tab"
      {...attrs}
    >
      <S.Label selected={selected}>{label}</S.Label>
      <S.Bar selected={selected} />
    </S.Container>
  )
}

export const Tab = memo(Component)
