import styled, { css } from 'styled-components'

export type StyledRadioProps = {
  disabled: boolean
}

const modifiers = {
  disabled: `
    pointer-events: none;
    opacity: 0.5;
  `,
}

export const Input = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  margin: 0;
  cursor: pointer;
`

export const Label = styled.label`
  font-family: Inter;
  font-size: var(--radio-label-font-size);
  color: var(--radio-label-text-color);
  margin: 0 8px 0 12px;
`

export const Container = styled.div<StyledRadioProps>`
  ${({ disabled }) => css`
    display: flex;
    align-items: center;
    position: relative;
    height: 40px;
    padding: 4px 0;
    box-sizing: border-box;
    user-select: none;
    ${disabled && modifiers.disabled}
  `}
`
