import React from 'react'

import Slider, { SwipeDirection } from 'react-slick'

import { PropsWithRequiredChildren } from '@/common/types/components'

import * as S from './styles'

export type CarouselProps = PropsWithRequiredChildren<{
  onAfterChange?(currentSlide: number): void
  onBeforeChange?(currentSlide: number, nextSlide: number): void
  onInit?(): void
  onSwipe?(swipeDirection: SwipeDirection): void
}>

export const Carousel = ({
  children,
  onBeforeChange,
  onAfterChange,
  ...props
}: CarouselProps) => (
  <S.Container itemPadding="16px">
    <Slider
      {...props}
      beforeChange={onBeforeChange}
      afterChange={onAfterChange}
      centerPadding="16px"
      dots={false}
      infinite
      slidesToShow={1}
      slidesToScroll={1}
      swipeToSlide
      centerMode
      arrows={false}
    >
      {children}
    </Slider>
  </S.Container>
)
