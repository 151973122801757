import styled, { css } from 'styled-components'

import {
  inputStyle,
  StyledInputProps,
  IconRight as InputIconRight,
} from '@/components/Input/styles'

export const Select = styled.select`
  ${({ hasIconRight, hasIconLeft }: StyledInputProps) => css`
    border: none;
    appearance: none;
    cursor: pointer;

    ${inputStyle({ hasIconRight, hasIconLeft })};
    ${hasIconRight && 'padding-right: 40px;'};
  `}
`

export const IconRight = styled(InputIconRight)`
  position: absolute;
  pointer-events: none;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`
