import React from 'react'

import { DefaultSVGProps } from '@/common/types/components'

export const House = ({ width, height, color, ...attrs }: DefaultSVGProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...attrs}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 12C5.55228 12 6 12.4477 6 13V18C6 18.5523 6.44772 19 7 19H17C17.5523 19 18 18.5523 18 18V13C18 12.4477 18.4477 12 19 12C19.5523 12 20 12.4477 20 13V18C20 19.6569 18.6569 21 17 21H7C5.34315 21 4 19.6569 4 18V13C4 12.4477 4.44772 12 5 12Z"
      fill={color}
    />
    <path
      d="M2.51916 7.86731C2.0227 8.18289 1.85261 8.88181 2.13924 9.42841C2.42586 9.975 3.06068 10.1623 3.55714 9.8467L12 4.47993L20.4429 9.8467C20.9393 10.1623 21.5741 9.975 21.8608 9.42841C22.1474 8.88181 21.9773 8.18289 21.4808 7.86731L12.7384 2.3101C12.5377 2.10222 12.2706 1.9928 12 2.00037C11.7294 1.9928 11.4623 2.10222 11.2616 2.3101L2.51916 7.86731Z"
      fill={color}
    />
  </svg>
)
