import React from 'react'

import { DefaultSVGProps } from '@/common/types/components'

export const Paper = ({ width, height, color, ...attrs }: DefaultSVGProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...attrs}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2941 4V6H17.957C17.5694 4.83481 16.5235 4 15.2941 4ZM18.1176 8H14.3529C13.8331 8 13.4118 7.55228 13.4118 7V4H6.82353C6.30373 4 5.88235 4.44772 5.88235 5V19C5.88235 19.5523 6.30373 20 6.82353 20H17.1765C17.6963 20 18.1176 19.5523 18.1176 19V8ZM13.4118 2H15.2941C17.5708 2 19.4698 3.71776 19.9059 6C19.9132 6.03815 19.92 6.07646 19.9265 6.11492C19.9748 6.40219 20 6.69797 20 7V8V19C20 20.6569 18.7359 22 17.1765 22H6.82353C5.26414 22 4 20.6569 4 19V5C4 3.34315 5.26414 2 6.82353 2H13.4118Z"
      fill={color}
    />
    <rect x="8" y="10" width="8" height="2" rx="1" fill={color} />
    <rect x="8" y="13" width="5" height="2" rx="1" fill={color} />
    <rect x="8" y="16" width="6" height="2" rx="1" fill={color} />
  </svg>
)
