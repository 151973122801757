import React from 'react'

import { DefaultSVGProps } from '@/common/types/components'

export const ArrowDown = ({
  width,
  height,
  color,
  ...attrs
}: DefaultSVGProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...attrs}
  >
    <path
      d="M18 9L12 15L6 9"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
