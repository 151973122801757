import styled, { css } from 'styled-components'

import { DEFAULT_CONTAINER_PADDING } from '@/common/constants'

export const Container = styled.header`
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px solid var(--header-border-color);
  padding-bottom: 8px;
  margin-bottom: 16px;
  width: calc(100% + ${DEFAULT_CONTAINER_PADDING}px);

  h2 {
    font-size: var(--header-title-font-size);
    color: var(--header-title-text-color);
    font-weight: 600;
  }
`

export const StepsContainer = styled.ol`
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0 ${DEFAULT_CONTAINER_PADDING}px 0 0;
  margin: 0;
`

export const StepNumber = styled.li<{ isCurrentStep: boolean }>`
  ${({ isCurrentStep }) => css`
    font-size: 12px;
    width: 24px;
    height: 24px;
    margin-left: 8px;
    border: 2px solid var(--header-step-border-color);
    text-align: center;
    background: ${isCurrentStep
      ? 'var(--header-current-step-background-color)'
      : 'var(--header-step-background-color)'};
    border-radius: 50%;
    color: var(--header-step-text-color);
    line-height: 20px;
    position: relative;
    display: inline-block;

    svg {
      margin-top: -2px;
      margin-left: -2px;
    }

    &:after {
      content: '';
      height: 2px;
      background: var(--header-step-lint-color);
      position: absolute;
      right: -10px;
      top: 10px;
      width: 8px;
    }

    &:last-child {
      &:after {
        opacity: 0;
      }
    }
  `}
`
