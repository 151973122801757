import React, { memo } from 'react'

import {
  PropsWithRequiredChildren,
  DefaultHTMLAttrs,
} from '@/common/types/components'

import * as S from './styles'

export type ContainerBoxProps = PropsWithRequiredChildren<
  DefaultHTMLAttrs<HTMLDivElement>
>

export const Component = ({ children, ...attrs }: ContainerBoxProps) => (
  <S.Container {...attrs}>{children}</S.Container>
)

export const ContainerBox = memo(Component)
