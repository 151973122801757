import React from 'react'

import { DefaultSVGProps } from '@/common/types/components'

export const CardMoney = ({
  width,
  height,
  color,
  ...attrs
}: DefaultSVGProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...attrs}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 4H6V6H4V4C4 2.89543 4.89543 2 6 2H22C23.1046 2 24 2.89543 24 4V14C24 15.1046 23.1046 16 22 16V14V9V4Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 9H2V20H18V9ZM2 7C0.895431 7 0 7.89543 0 9V20C0 21.1046 0.895431 22 2 22H18C19.1046 22 20 21.1046 20 20V9C20 7.89543 19.1046 7 18 7H2Z"
      fill={color}
    />
    <path
      d="M10.2831 10.7563C10.2831 10.3386 9.96765 10 9.57853 10C9.18941 10 8.87397 10.3386 8.87397 10.7563V11.0087C8.01646 11.0817 7.27548 11.7204 7.06043 12.6299L7.06002 12.6317C6.83026 13.6132 7.27733 14.6028 8.10677 15.0505L8.10946 15.0519C8.38904 15.2012 8.70699 15.2876 9.03288 15.2876H9.99418C10.3404 15.2876 10.5909 15.5776 10.5909 15.8898C10.5909 16.2042 10.3441 16.492 9.99418 16.492H9.58313L9.57853 16.492L9.57394 16.492H8.01298C7.62386 16.492 7.30841 16.8306 7.30841 17.2483C7.30841 17.666 7.62386 18.0046 8.01298 18.0046H8.87397V18.2437C8.87397 18.6614 9.18941 19 9.57853 19C9.96765 19 10.2831 18.6614 10.2831 18.2437V17.9827C11.2427 17.8363 12 16.9714 12 15.8898C12 14.6995 11.0782 13.775 9.99418 13.775H9.03288C8.9372 13.775 8.83365 13.7489 8.73847 13.6984C8.47888 13.5575 8.36371 13.2709 8.42646 13.0012C8.49114 12.7293 8.73415 12.5143 9.03875 12.5143H9.57581C9.57671 12.5143 9.57762 12.5143 9.57853 12.5143C9.57944 12.5143 9.58035 12.5143 9.58126 12.5143H11.0199C11.4091 12.5143 11.7245 12.1757 11.7245 11.758C11.7245 11.3403 11.4091 11.0017 11.0199 11.0017H10.2831V10.7563Z"
      fill={color}
    />
  </svg>
)
